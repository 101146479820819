<template>
  <component
    :is="tag"
    class="font-semibold inline-flex items-center justify-center leading-none"
    :class="[borderClasses, colorClasses, radiusClasses, sizeClasses]"
  >
    <slot>
      {{ initials }}
    </slot>
  </component>
</template>

<script>
import { str } from '~/support/utils';

import { BASE, FULL, LG, SM, XL, XXXXXL } from '../constants';

export const BLUE = 'blue';
export const INDIGO = 'indigo';
export const GRAY = 'gray';
export const PURPLE = 'purple';
export const WHITE = 'white';
export const RADIUS_SIZES = [FULL, LG];
export const SIZES = [BASE, LG, SM, XL, XXXXXL];
export const RADIUS_CLASS_MAP = {
  [FULL]: 'rounded-full',
  [LG]: 'rounded-lg',
  [XXXXXL]: 'rounded-xl',
};
export const SIZE_CLASS_MAP = {
  [SM]: 'h-6 w-6 text-xs',
  [BASE]: 'h-8 w-8 text-xs',
  [LG]: 'h-12 w-12 text-lg',
  [XL]: 'h-16 w-16 text-2xl',
  [XXXXXL]: 'h-32 w-32 text-4xl',
};
export const BORDER_SIZE_MAP = {
  [SM]: 'border',
  [BASE]: 'border-2',
  [LG]: 'border-2',
  [XL]: 'border-2',
  [XXXXXL]: 'border-4',
};
export const COLOR_CLASS_MAP = {
  [INDIGO]: 'bg-indigo-100 text-gray-800 border-indigo-200',
  [GRAY]: 'bg-gray-100 text-gray-900 border-gray-900',
  [PURPLE]: 'bg-purple-100 text-purple-600 border-purple-600',
  [WHITE]: 'bg-white text-gray-600 border-gray-300',
  [BLUE]: 'bg-blue-600 text-white border-white',
};

export default {
  name: 'CxAvatar',

  props: {
    borderless: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: PURPLE,
    },

    name: {
      type: String,
      default: '',
    },

    radius: {
      type: String,
      default: FULL,
      validator: (value) => RADIUS_SIZES.includes(value),
    },

    size: {
      type: String,
      default: BASE,
      validator: (value) => SIZES.includes(value),
    },

    tag: {
      type: String,
      default: 'span',
    },
  },

  computed: {
    borderClasses() {
      return this.borderless ? '' : this.borderSize;
    },

    borderSize() {
      return BORDER_SIZE_MAP[this.size];
    },

    colorClasses() {
      return COLOR_CLASS_MAP[this.color];
    },

    initials() {
      return str.initials(this.name);
    },

    radiusClasses() {
      return RADIUS_CLASS_MAP[this.radius];
    },

    sizeClasses() {
      return SIZE_CLASS_MAP[this.size];
    },
  },
};
</script>
